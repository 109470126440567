.page-footer {
    margin-top: auto;
    background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
}

.page-footer__wrap {
    padding-top: 60px;
    padding-bottom: 60px;

    @media (min-width: $tablet) {
        display: flex;
    }

    @media (min-width: $desktop) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.page-footer__content {
    @media (min-width: $desktop) {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    } 
}

.page-footer__form {
    @media (min-width: $desktop) {
        margin-right: 130px;
    }
}