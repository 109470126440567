.form {
    margin-bottom: 60px;

    &--modal {
        margin-bottom: 0;
    }

    @media (min-width: $tablet) {
        margin-bottom: 80px;

        &--modal {
            margin-bottom: 0;
        }
    }

    @media (min-width: $desktop) {
        margin-bottom: 0;
    }
}

.form__svg {
    position: absolute;
    top: 8px;
    right: 18px;
    fill: #FFFFFF;
    transition: fill 0.3s ease;
    cursor: pointer;

    &:hover {
        @media (hover: hover) {
            fill: #7785FF;
        }
    }

    &:active {
        fill: #7785FF;
    }

    @media (min-width: $tablet) {
        width: 42px;
        height: 42px;
        top: 20px;
        right: 38px;
    }

    @media (min-width: $desktop) {
        top: 65px;
        right: 0;
    }
}

.form__wrapper {

    &--modal {
        position: relative;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    @media (min-width: $tablet) {
        position: relative;
    }

    @media (min-width: $desktop) {
        &--modal {
            padding: 0;
            margin-bottom: 0;
        }
    }
}


.form__container {
    @media (min-width: $desktop) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
    }
}

.form__title {
    @include title;
    color: #96CDFF;
    margin-bottom: 30px;

    &--modal {
       margin-bottom: 20px; 
       width: 80%;
       font-size: 24px; 
       text-transform: none;
    }

    @media (min-width: $tablet) {
        &--modal {
            font-size: 30px;
        }
    }

    @media (min-width: $desktop) {
        margin-bottom: 60px;

        &--modal {
            font-size: 50px;
            margin-bottom: 17px;
        }
    }
}

.form__input-wrap {
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop) {
        width: 50%;
        margin-right: 50px;
    }
}

.form__input-list {
    display: flex;
    flex-direction: column;
}

.form__input {
    @include input;
    padding: 30px 20px 10px 20px;
    margin-bottom: 30px;
    color: #FFFFFF;

    &--modal {
        padding: 10px 20px 10px 20px;
        margin-bottom: 20px;
    }

    &_error {
        color: red;
        border-color: red;
    }

    @media (min-width: $desktop) {
        margin-bottom: 30px; 
    }
}

.form__comment {
    min-height: 120px;
    font-family: $font-family;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
    padding: 30px 20px 10px 20px;
    resize: none;
    background-color: transparent;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    outline: none;

    &::placeholder {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        color: #6F7DFF;
    }

    &--modal {
        min-height: 50px;
        margin-bottom: 10px;
    }

    @media (min-width: $tablet) {
        &--modal {
            min-height: 80px;
        }
    }
}

.form__checkbox-wrap {
    @media (min-width: $tablet) {
        margin-bottom: 50px;
    }

    @media (min-width: $desktop) {
        width: 50%;
        margin-bottom: 0;
    }
}

.form__subtitle {
    margin: 0;
    margin-bottom: 30px;
    padding: 25px 20px 10px 20px;
    font-weight: 400;
    font-size: 18px;
    color: #6F7DFF;
    border-bottom: 2px solid #FFFFFF;

    &--modal {
        padding: 10px 20px 10px 20px; 
    }

    @media (min-width: $desktop) {
        padding-bottom: 15px;
    }
}

.form__checkbox-list {
    @include list-reset;


    @media (min-width: $tablet) {
        &--modal {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: $desktop) {
        &--modal {
            display: block;
        }
    }
}

.form__checkbox-item {
    margin-bottom: 25px;

    &--modal {
        margin-bottom: 20px; 
    }

    @media (min-width: $desktop) {
        margin-bottom: 30px;
    }
}

.form__button {
    @media (min-width: $desktop) {
        padding-top: 25px;
        padding-bottom: 25px;

        &--modal {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}