@font-face {
    font-family: "PT Sans";
    src: url("/fonts/ptsans.woff2") format("woff2"),
    url("/fonts/ptsans.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  
  @font-face {
    font-family: "PT Sans";
    src: url("/fonts/ptsansbold.woff2") format("woff2"),
    url("/fonts/ptsansbold.woff") format("woff");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "PT Sans italic";
    src: url("/fonts/ptsansitalic.woff2") format("woff2"),
    url("/fonts/ptsansitalic.woff") format("woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "PT Sans italic";
    src: url("/fonts/ptsansbolditalic.woff2") format("woff2"),
    url("/fonts/ptsansbolditalic.woff") format("woff");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }