//font
$font-family: "PT Sans", "Arial", sans-serif;

//viewports
$tablet: 768px;
$desktop: 1220px;
$mobile-only: 767px;

//color
$body-color: rgba(19, 21, 35, 1);
$fonts-color: rgba(255, 255, 255, 1);

//padding
$padding-mobile: 0 18px;
$padding-tablet: 0 38px;
$padding-desktop: 0 101px;

//wrapper
$wrapper-desktop: 1440px;
