.checklist__text {
    margin: 0;
    margin-bottom: 40px;
    padding-bottom: 10px;
    font-size: 20px;
    border-bottom: 2px solid #5DA6E1;

    @media (min-width: $tablet) {
        font-size: 24px;
    }

    @media (min-width: $desktop) {
        &--outside {
            padding-bottom: 70px;
        }
    }
}

.checklist__list {
    margin-top: 40px;
    padding-left: 15px;
    list-style: disc;

    @media (min-width: $tablet) {
        padding-left: 25px; 
    }

    @media (min-width: $desktop) {
        padding-left: 30px;
    }
}

.checklist__item {
    margin-bottom: 30px;
}