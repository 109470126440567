.serm-step {
    background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
    padding: 60px 0;

    @media (min-width: $tablet) {
      padding-bottom: 80px;
    }

    @media (min-width: $desktop) {
      padding-top: 80px;
    }
}

.serm-step__wrap {
  @media (min-width: $tablet) {
    display: flex;
  }
}

.serm-step__title {
    margin: 0;
    margin-bottom: 60px;
    font-size: 40px;
    text-transform: uppercase;
    color: #96CDFF;

    @media (min-width: $tablet) {
      margin-bottom: 80px;
      font-size: 50px;
    }

    @media (min-width: $desktop) {
      margin-bottom: 60px;
    }
}

.serm-step__list {
    @include list-reset;

    @media (min-width: $desktop) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 60px;
    }
}

.serm-step__item {
    display: flex;
    align-items: center;

    &:nth-last-child(n+2) {
      margin-bottom: 60px;
    }

    & h3 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 24px;
        color: #96CDFF;
    }

    @media (min-width: $desktop) {
      flex-direction: column;

      align-items: flex-start;

      &:nth-last-child(n+2) {
        margin-bottom: 0;
      }

      &--img {
        grid-column: span 2;
        margin: auto 0;
      }
    }
}

.serm-step__img {
    width: 100%;
    height: 135px;
    background-image: url("/img/serm-step__img.png");
    background-size: auto 135px;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: $tablet) {
      height: 253px;
      background-size: auto 253px;
    }

    @media (min-width: $desktop) {
      height: 311px;
      background-size: auto 311px;
  }
}

.serm-step__number {
        margin: 0;
        margin-right: 20px;
        font-weight: 700;
        font-size: 80px;
        line-height: 104px;
        color: #4D55B2;

        @media (min-width: $desktop) {
          margin-right: 0;
          margin-bottom: 20px;
        }
}

.serm-step__checklist {
  padding-left: 18px;
  list-style: disc;
}