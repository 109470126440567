.seo-step {
    @include list-reset;

    @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 60px;
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
        gap: 70px;
    }
}