.toggle {
    @include button-reset;
    position: absolute;
    display: block;
    width: 37px;
    height: 37px;
    padding: 0;
    z-index: 101;
  
    &::before,
    &::after {
      content: "";
      transition: transform 0.3s linear, background-color 0.3s linear;
    }
  
    &--open::before,
    &--open::after {
      position: absolute;
      left: 0;
      width: 37px;
      height: 3px;
      background-color: #FFFFFF;
      transform: rotate(0);
    }
  
    &--open::before {
      top: 6px;
      box-shadow: 0 9px 0 0 #FFFFFF;
    }
  
    &--open::after {
      top: 24px;
      bottom: 0;
    }
  
    &--close::before,
    &--close::after {
      position: absolute;
      top: 13px;
      right: 0;
      width: 37px;
      height: 3px;
      background-color: #FFFFFF;
    }
  
    &--close::before {
      transform: rotate(45deg);
      box-shadow: none;
    }
  
    &--close::after {
      transform: rotate(-45deg);
    }

    @media (min-width: $tablet) {
      width: 54px;
      height: 54px;

      &--open::before,
      &--open::after {
        width: 54px;
        background-color: #FFFFFF;
        transform: rotate(0);
      }
  
      &--open::before {
        top: 7px;
      }
  
      &--open::after {
        top: 25px;
      }
  
      &--close::before,
      &--close::after {
        position: absolute;
        top: 14px;
        width: 54px;
      }
    }
  }