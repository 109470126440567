.main-nav {
    position: absolute;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: $padding-mobile;
    padding: 105px 40px 50px 40px;
    background: #4953AA;
    z-index: 100;
    transition: top 0.4s ease-in-out, opacity 0.4s ease-in-out;

    &--close {
        top: -830px;
        opacity: 0;
        transition-delay: 0.3s;
    }

    &--open {
        top: 0;
        opacity: 1;
        transition-delay: 0;
    }

    @media (min-width: $desktop) {
        position: static;
        width: auto;
        padding: 0;
        background: none;
        opacity: 1;
    }
}

.main-nav__list {
    @include list-reset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $tablet) {
        align-items: center;
    }

    @media (min-width: $desktop) {
        flex-direction: row;
    }
}

.main-nav__item {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 700;

    @media (min-width: $tablet) {
        text-align: center;
    }

    @media (min-width: $desktop) {
        padding: 0;
        margin-right: 55px;
        font-weight: 400;
    }
}

.main-nav__link {
    @include link-reset;
    display: block;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
    line-height: 30px;
    cursor: pointer;

    transform: translateY(-15px);
    opacity: 0;
    transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out, color 0.2s linear;

    &--services,
    &--company,
    &--contacts {
        color: #7785FF;
    }

    &:hover {
        @media (hover: hover) {
            color: #7785FF;
        }
    }

    &:active {
        color: #7785FF;
    }

    @media (min-width: $desktop) {
        transform: translateY(0);
        opacity: 1;
        font-size: 21px;
    }
}

.active {
  color: #7785FF;
}

.main-nav--open .main-nav__link {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.3s;
}

.main-nav--close .main-nav__link {
    transition-delay: 0;
}
