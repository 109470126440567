.benefit {
    padding: 60px 0;
    background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);

    @media (min-width: $tablet) {
        padding-bottom: 80px;
    }

    @media (min-width: $desktop) {
        padding-top: 80px;
        padding-bottom: 100px;
    }
}

.benefit__wrap {
    @media (min-width: $tablet) {
        display: flex;
    }
}

.benefit__title {
    margin: 0;
    margin-bottom: 60px;
    font-size: 30px;
    text-transform: uppercase;

    @media (min-width: $tablet) {
        margin-bottom: 80px;
        font-size: 50px;
    }
}

.benefit__list {
    @include list-reset;

    @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    @media (min-width: $desktop) {
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
    }
}