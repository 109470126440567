@mixin list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin button-reset {
    background-color: transparent;
    border: none;
}

@mixin button {
    @include button-reset;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    background-image: linear-gradient(180deg, #96C6FF 0%, #5749AA 100%);

    @media (min-width: $desktop) {
        font-size: 25px;
        line-height: 30px;
    }
}

@mixin link-reset {
    text-decoration: none;
}

@mixin default-link {
    @include link-reset;
    color: rgba(0, 0, 0, 1);
}

@mixin default-title {
    margin: 0;
    padding: 0;
}

@mixin default-subtitle {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
}

@mixin wrapper {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0;
}

@mixin input {
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    font-family: $font-family;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #FFFFFF;
    outline: none;

    &::placeholder {
        font-family: $font-family;
        font-weight: 400;
        font-size: 18px;
        color: #6F7DFF;
    }
}

@mixin title {
    @include default-title;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;

    @media (min-width: $tablet) {
        font-size: 50px;
    }
}

@mixin wrapper-mobile {
    padding: 0 18px;
}

@mixin wrapper-tablet {
    padding: 0 38px;
}

@mixin wrapper-desktop {
    width: 100%;
    box-sizing: border-box;
    max-width: $wrapper-desktop;
    margin: 0 auto;
    padding: 0 101px;
}

