@font-face {
  font-family: "PT Sans";
  src: url("/fonts/ptsans.woff2") format("woff2"), url("/fonts/ptsans.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans";
  src: url("/fonts/ptsansbold.woff2") format("woff2"), url("/fonts/ptsansbold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans italic";
  src: url("/fonts/ptsansitalic.woff2") format("woff2"), url("/fonts/ptsansitalic.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "PT Sans italic";
  src: url("/fonts/ptsansbolditalic.woff2") format("woff2"), url("/fonts/ptsansbolditalic.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 320px;
  font-family: "PT Sans", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  background-color: rgb(19, 21, 35);
  color: rgb(255, 255, 255);
  overflow-x: hidden;
  max-width: 100%;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.wrapper {
  padding: 0 18px;
}
@media (min-width: 768px) {
  .wrapper {
    padding: 0 38px;
  }
}
@media (min-width: 1220px) {
  .wrapper {
    width: 100%;
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 101px;
  }
}

.btn {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 34px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  border: none;
  background-image: linear-gradient(180deg, #96C6FF 0%, #5749AA 100%);
  box-shadow: 2px 2px 0px #3E82B9;
  border-radius: 10px;
  cursor: pointer;
}
@media (hover: hover) {
  .btn:hover {
    background-image: linear-gradient(180deg, #CB69FA 0%, #615BBC 100%);
  }
}
.btn:active, .btn:disabled {
  background-image: linear-gradient(180deg, #CB69FA 0%, #615BBC 100%);
}
.btn:disabled {
  cursor: auto;
}

@media (min-width: 768px) {
  .element {
    display: flex;
    position: relative;
    flex-shrink: 0;
    margin-right: 20px;
    width: 65px;
  }
  .element::before, .element::after {
    content: "";
    position: absolute;
    background-color: #ffffff;
  }
  .element::before {
    top: -60px;
    right: 0;
    left: -20%;
    margin: auto;
    width: 3px;
    height: 720px;
  }
  .element::after {
    top: 660px;
    left: calc(43% - 10px);
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
}
.element--promo::before {
  top: 2px;
  height: 100%;
}
.element--promo::after {
  display: none;
}
.element--tasks::before {
  height: 350px;
}
.element--tasks::after {
  top: 285px;
}
.element--audit-key::before {
  height: 450px;
}
.element--audit-key::after {
  top: 380px;
}
.element--audit-step::before {
  height: 450px;
}
.element--audit-step::after {
  top: 380px;
}
@media (min-width: 1220px) {
  .element {
    margin-right: 40px;
  }
  .element::before {
    top: -80px;
    left: 0;
    height: 450px;
  }
  .element::after {
    top: 370px;
    left: calc(52% - 10px);
  }
  .element--promo::before {
    top: 0;
    height: 100%;
  }
  .element--tasks::before {
    height: 450px;
  }
  .element--tasks::before {
    height: 240px;
  }
  .element--tasks::after {
    top: 160px;
  }
  .element--audit-key::before {
    height: 350px;
  }
  .element--audit-key::after {
    top: 260px;
  }
  .element--audit-step::before {
    height: 350px;
  }
  .element--audit-step::after {
    top: 260px;
  }
}

.page-header {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .page-header {
    padding-top: 40px;
    padding-bottom: 25px;
  }
}
@media (min-width: 1220px) {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

.page-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header img {
  display: block;
}
@media (min-width: 768px) {
  .page-header img {
    width: 158px;
    height: 81px;
  }
}
@media (min-width: 1220px) {
  .page-header img {
    width: 178px;
    height: 92px;
    margin-right: 45px;
  }
}

.page-header__toggle {
  position: relative;
  width: 37px;
  height: 37px;
}
@media (min-width: 768px) {
  .page-header__toggle {
    width: 54px;
  }
}
@media (min-width: 1220px) {
  .page-header__toggle {
    display: none;
  }
}

.main-nav {
  position: absolute;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
  padding: 105px 40px 50px 40px;
  background: #4953AA;
  z-index: 100;
  transition: top 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.main-nav--close {
  top: -830px;
  opacity: 0;
  transition-delay: 0.3s;
}
.main-nav--open {
  top: 0;
  opacity: 1;
  transition-delay: 0;
}
@media (min-width: 1220px) {
  .main-nav {
    position: static;
    width: auto;
    padding: 0;
    background: none;
    opacity: 1;
  }
}

.main-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .main-nav__list {
    align-items: center;
  }
}
@media (min-width: 1220px) {
  .main-nav__list {
    flex-direction: row;
  }
}

.main-nav__item {
  padding-bottom: 30px;
  font-size: 20px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .main-nav__item {
    text-align: center;
  }
}
@media (min-width: 1220px) {
  .main-nav__item {
    padding: 0;
    margin-right: 55px;
    font-weight: 400;
  }
}

.main-nav__link {
  text-decoration: none;
  display: block;
  font-size: 20px;
  color: rgb(255, 255, 255);
  line-height: 30px;
  cursor: pointer;
  transform: translateY(-15px);
  opacity: 0;
  transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out, color 0.2s linear;
}
.main-nav__link--services, .main-nav__link--company, .main-nav__link--contacts {
  color: #7785FF;
}
@media (hover: hover) {
  .main-nav__link:hover {
    color: #7785FF;
  }
}
.main-nav__link:active {
  color: #7785FF;
}
@media (min-width: 1220px) {
  .main-nav__link {
    transform: translateY(0);
    opacity: 1;
    font-size: 21px;
  }
}

.active {
  color: #7785FF;
}

.main-nav--open .main-nav__link {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.3s;
}

.main-nav--close .main-nav__link {
  transition-delay: 0;
}

.toggle {
  background-color: transparent;
  border: none;
  position: absolute;
  display: block;
  width: 37px;
  height: 37px;
  padding: 0;
  z-index: 101;
}
.toggle::before, .toggle::after {
  content: "";
  transition: transform 0.3s linear, background-color 0.3s linear;
}
.toggle--open::before, .toggle--open::after {
  position: absolute;
  left: 0;
  width: 37px;
  height: 3px;
  background-color: #FFFFFF;
  transform: rotate(0);
}
.toggle--open::before {
  top: 6px;
  box-shadow: 0 9px 0 0 #FFFFFF;
}
.toggle--open::after {
  top: 24px;
  bottom: 0;
}
.toggle--close::before, .toggle--close::after {
  position: absolute;
  top: 13px;
  right: 0;
  width: 37px;
  height: 3px;
  background-color: #FFFFFF;
}
.toggle--close::before {
  transform: rotate(45deg);
  box-shadow: none;
}
.toggle--close::after {
  transform: rotate(-45deg);
}
@media (min-width: 768px) {
  .toggle {
    width: 54px;
    height: 54px;
  }
  .toggle--open::before, .toggle--open::after {
    width: 54px;
    background-color: #FFFFFF;
    transform: rotate(0);
  }
  .toggle--open::before {
    top: 7px;
  }
  .toggle--open::after {
    top: 25px;
  }
  .toggle--close::before, .toggle--close::after {
    position: absolute;
    top: 14px;
    width: 54px;
  }
}

.submenu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .submenu {
    align-items: center;
  }
}
@media (min-width: 1220px) {
  .submenu {
    position: absolute;
    display: none;
    top: 124px;
    padding: 60px 100px 30px 100px;
    background-color: #4953AA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -7px 7px 0px #5DA6E1;
    border-radius: 10px;
  }
  .submenu_active {
    display: block;
  }
}

.submenu__item {
  margin-bottom: 15px;
  font-weight: 400;
}
@media (min-width: 1220px) {
  .submenu__item {
    margin-bottom: 30px;
  }
}

.submenu__link {
  text-decoration: none;
  display: block;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transform: translateY(-15px);
  opacity: 0;
  transition: transform 0.35s ease-in-out, opacity 0.5s ease-in-out, color 0.2s linear;
}
.submenu__link--seo, .submenu__link--serm, .submenu__link--web, .submenu__link--audit {
  color: #96CDFF;
}
@media (hover: hover) {
  .submenu__link:hover {
    color: #96CDFF;
  }
}
.submenu__link:active {
  color: #96CDFF;
}
@media (min-width: 1220px) {
  .submenu__link {
    opacity: 1;
  }
}

.main-nav--open .submenu__link {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.3s;
}

.main-nav--close .submenu__link {
  transition-delay: 0;
}

@media (min-width: 1220px) {
  .connect {
    display: flex;
    align-items: center;
  }
}

.connect__mail {
  display: none;
  transition: color 0.2s linear;
}
@media (hover: hover) {
  .connect__mail:hover {
    color: #FFFFFF;
  }
}
.connect__mail:active {
  color: #FFFFFF;
}
@media (min-width: 1220px) {
  .connect__mail {
    opacity: 1;
  }
}
@media (min-width: 1220px) {
  .connect__mail {
    display: block;
    color: #7785FF;
    font-size: 21px;
    text-decoration: none;
    margin-right: 17px;
  }
}

.connect__btn {
  display: none;
}
@media (min-width: 1220px) {
  .connect__btn {
    display: block;
  }
}

.page-footer {
  margin-top: auto;
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
}

.page-footer__wrap {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .page-footer__wrap {
    display: flex;
  }
}
@media (min-width: 1220px) {
  .page-footer__wrap {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1220px) {
  .page-footer__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 1220px) {
  .page-footer__form {
    margin-right: 130px;
  }
}

.form {
  margin-bottom: 60px;
}
.form--modal {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .form {
    margin-bottom: 80px;
  }
  .form--modal {
    margin-bottom: 0;
  }
}
@media (min-width: 1220px) {
  .form {
    margin-bottom: 0;
  }
}

.form__svg {
  position: absolute;
  top: 8px;
  right: 18px;
  fill: #FFFFFF;
  transition: fill 0.3s ease;
  cursor: pointer;
}
@media (hover: hover) {
  .form__svg:hover {
    fill: #7785FF;
  }
}
.form__svg:active {
  fill: #7785FF;
}
@media (min-width: 768px) {
  .form__svg {
    width: 42px;
    height: 42px;
    top: 20px;
    right: 38px;
  }
}
@media (min-width: 1220px) {
  .form__svg {
    top: 65px;
    right: 0;
  }
}

.form__wrapper--modal {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .form__wrapper {
    position: relative;
  }
}
@media (min-width: 1220px) {
  .form__wrapper--modal {
    padding: 0;
    margin-bottom: 0;
  }
}

@media (min-width: 1220px) {
  .form__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.form__title {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;
  color: #96CDFF;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .form__title {
    font-size: 50px;
  }
}
.form__title--modal {
  margin-bottom: 20px;
  width: 80%;
  font-size: 24px;
  text-transform: none;
}
@media (min-width: 768px) {
  .form__title--modal {
    font-size: 30px;
  }
}
@media (min-width: 1220px) {
  .form__title {
    margin-bottom: 60px;
  }
  .form__title--modal {
    font-size: 50px;
    margin-bottom: 17px;
  }
}

.form__input-wrap {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1220px) {
  .form__input-wrap {
    width: 50%;
    margin-right: 50px;
  }
}

.form__input-list {
  display: flex;
  flex-direction: column;
}

.form__input {
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  font-family: "PT Sans", "Arial", sans-serif;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #FFFFFF;
  outline: none;
  padding: 30px 20px 10px 20px;
  margin-bottom: 30px;
  color: #FFFFFF;
}
.form__input::placeholder {
  font-family: "PT Sans", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #6F7DFF;
}
.form__input--modal {
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
}
.form__input_error {
  color: red;
  border-color: red;
}
@media (min-width: 1220px) {
  .form__input {
    margin-bottom: 30px;
  }
}

.form__comment {
  min-height: 120px;
  font-family: "PT Sans", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 30px;
  padding: 30px 20px 10px 20px;
  resize: none;
  background-color: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  outline: none;
}
.form__comment::placeholder {
  font-family: "PT Sans", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #6F7DFF;
}
.form__comment--modal {
  min-height: 50px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .form__comment--modal {
    min-height: 80px;
  }
}

@media (min-width: 768px) {
  .form__checkbox-wrap {
    margin-bottom: 50px;
  }
}
@media (min-width: 1220px) {
  .form__checkbox-wrap {
    width: 50%;
    margin-bottom: 0;
  }
}

.form__subtitle {
  margin: 0;
  margin-bottom: 30px;
  padding: 25px 20px 10px 20px;
  font-weight: 400;
  font-size: 18px;
  color: #6F7DFF;
  border-bottom: 2px solid #FFFFFF;
}
.form__subtitle--modal {
  padding: 10px 20px 10px 20px;
}
@media (min-width: 1220px) {
  .form__subtitle {
    padding-bottom: 15px;
  }
}

.form__checkbox-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .form__checkbox-list--modal {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1220px) {
  .form__checkbox-list--modal {
    display: block;
  }
}

.form__checkbox-item {
  margin-bottom: 25px;
}
.form__checkbox-item--modal {
  margin-bottom: 20px;
}
@media (min-width: 1220px) {
  .form__checkbox-item {
    margin-bottom: 30px;
  }
}

@media (min-width: 1220px) {
  .form__button {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .form__button--modal {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.checkbox-control {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkbox-control .checkbox-control__mark,
.checkbox-control .checkbox-control__label {
  transition: border-color 0.2s linear, color 0.2s linear;
}
@media (hover: hover) {
  .checkbox-control:hover .checkbox-control__mark {
    border-color: #6F7DFF;
  }
}
@media (hover: hover) {
  .checkbox-control:hover .checkbox-control__label {
    color: #6F7DFF;
  }
}

.checkbox-control__mark {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 2px solid rgb(205, 205, 205);
  border-radius: 5px;
}
.checkbox-control__mark::before {
  position: absolute;
  content: "";
  width: 27px;
  height: 26px;
  background-image: url("/img/control__mark.svg");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: color 0.1s linear, opacity 0.1s linear;
}

.checkbox-control__input:checked ~ .checkbox-control__mark {
  border-color: #6F7DFF;
}
.checkbox-control__input:checked ~ .checkbox-control__mark::before {
  opacity: 1;
}

.checkbox-control__input:checked ~ .checkbox-control__label {
  color: #6F7DFF;
}

@media (min-width: 1220px) {
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 60%;
  }
}

.contacts__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media (min-width: 1220px) {
  .contacts__wrap {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .contacts__logo img {
    width: 157px;
    height: 81px;
  }
}
@media (min-width: 1220px) {
  .contacts__logo img {
    width: 178px;
    height: 92px;
    margin-bottom: 20px;
  }
}

.contacts__tme {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #4953AA;
  border-radius: 50px;
  box-shadow: inset 0px 2px 0px #FFFFFF;
  transition: background-color 0.35s ease-in-out;
}
@media (hover: hover) {
  .contacts__tme:hover {
    background-color: #9177D7;
  }
}
.contacts__tme:active {
  color: #9177D7;
}
.contacts__tme svg {
  width: 27px;
  height: 20px;
}
@media (min-width: 1220px) {
  .contacts__tme {
    width: 65px;
    height: 65px;
  }
  .contacts__tme svg {
    width: 37px;
    height: 30px;
  }
}

.contacts__mail {
  text-decoration: none;
  color: rgb(0, 0, 0);
  display: block;
  font-size: 21px;
  color: #7785FF;
  margin-bottom: 30px;
  transition: color 0.2s linear;
}
@media (hover: hover) {
  .contacts__mail:hover {
    color: #FFFFFF;
  }
}
.contacts__mail:active {
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .contacts__mail {
    margin-bottom: 60px;
  }
}

.contacts__documents {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
.contacts__documents a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  width: 90%;
  margin-bottom: 20px;
  font-size: 21px;
  color: #FFFFFF;
  transition: color 0.2s linear;
  cursor: pointer;
}
@media (hover: hover) {
  .contacts__documents a:hover {
    color: #7785FF;
  }
}
.contacts__documents a:active {
  color: #7785FF;
}
@media (min-width: 1220px) {
  .contacts__documents {
    align-items: flex-end;
  }
  .contacts__documents a {
    text-align: right;
    margin-bottom: 30px;
  }
}

a.contacts__agreement,
a.contacts__personal {
  color: #7785FF;
}

.contacts__copyright {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  color: #444A92;
}
@media (min-width: 1220px) {
  .contacts__copyright {
    font-size: 21px;
    text-align: right;
  }
}

.promo {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .promo {
    display: flex;
    margin-bottom: 0;
  }
}
@media (min-width: 1220px) {
  .promo {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .promo__element {
    display: flex;
    justify-content: flex-start;
  }
}

.element__tme {
  display: none;
}
@media (min-width: 768px) {
  .element__tme {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #4953AA;
    border-radius: 50px;
    box-shadow: inset 0px -2px 0px #FFFFFF;
    transition: background-color 0.35s ease-in-out;
    z-index: 2;
  }
}
@media (min-width: 768px) and (hover: hover) {
  .element__tme:hover {
    background-color: #9177D7;
  }
}
@media (min-width: 768px) {
  .element__tme:active {
    color: #9177D7;
  }
}
@media (min-width: 768px) {
  .element__tme svg {
    width: 27px;
    height: 20px;
  }
}
@media (min-width: 768px) and (min-width: 1220px) {
  .element__tme {
    width: 65px;
    height: 65px;
  }
  .element__tme svg {
    width: 37px;
    height: 30px;
  }
}

.promo__content {
  position: relative;
  padding-top: 65px;
  margin-bottom: 460px;
}
.promo__content::before, .promo__content::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: 100%;
}
.promo__content::before {
  top: 115%;
  width: 324px;
  background-image: url("/img/round.png");
}
.promo__content::after {
  top: 140%;
  width: 148px;
  background-image: url("/img/birdve.png");
}
@media (min-width: 768px) {
  .promo__content {
    width: 100%;
    padding-top: 90px;
    margin-bottom: 650px;
  }
  .promo__content::before {
    top: 110%;
    width: 520px;
  }
  .promo__content::after {
    top: 136%;
    width: 245px;
  }
}
@media (min-width: 1220px) {
  .promo__content {
    margin-bottom: 150px;
  }
  .promo__content::before, .promo__content::after {
    bottom: 0;
    margin: 0;
  }
  .promo__content::before {
    top: 15%;
    left: 60%;
    width: 436px;
    height: 436px;
    animation-name: rotation;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .promo__content::after {
    top: 38%;
    left: 71%;
    width: 198px;
  }
}

@keyframes rotation {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: scale(1.2) rotate(90deg);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.3) rotate(180deg);
    opacity: 1;
  }
  75% {
    transform: scale(1.2) rotate(270deg);
    opacity: 0.3;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}
.promo__text {
  position: absolute;
  margin: 0;
  margin-top: -75px;
  padding-left: 65px;
  font-weight: 700;
  font-size: 145px;
  line-height: 60%;
  color: #232651;
  z-index: -1;
}
@media (min-width: 768px) {
  .promo__text {
    margin-top: -120px;
    padding-left: 130px;
    font-size: 230px;
  }
}

.promo__title {
  margin: 0;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 45px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .promo__title {
    font-size: 85px;
  }
}
@media (min-width: 1220px) {
  .promo__title {
    font-size: 84px;
  }
}

@media (min-width: 768px) {
  .promo__button {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (min-width: 1220px) {
  .promo__button {
    width: 37%;
  }
}

@media (min-width: 768px) {
  .skills {
    display: flex;
  }
}

@media (min-width: 768px) {
  .skills__wrap {
    margin-bottom: 20px;
  }
}
@media (min-width: 1220px) {
  .skills__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
  }
}

.skills__content {
  margin-bottom: 60px;
}
@media (min-width: 1220px) {
  .skills__content {
    margin-bottom: 0;
    margin-right: 125px;
  }
}

.skills__title {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 20px;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .skills__title {
    font-size: 50px;
  }
}
.skills__title--about {
  font-size: 24px;
}
@media (min-width: 1220px) {
  .skills__title {
    margin-bottom: 50px;
  }
}

.skills__text {
  margin: 0;
  margin-bottom: 30px;
}
@media (min-width: 1220px) {
  .skills__text {
    margin-bottom: 40px;
  }
}

.skills__link {
  text-decoration: none;
}

.skills__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .skills__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (min-width: 1220px) {
  .skills__list {
    gap: 20px;
  }
}

.skills__item {
  margin-bottom: 60px;
  padding-left: 86px;
  background-repeat: no-repeat;
}
.skills__item h3 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 20px;
}
.skills__item--team {
  background-image: url("/img/team.svg");
}
.skills__item--audit {
  background-image: url("/img/audit.svg");
}
.skills__item--strategy {
  background-image: url("/img/strategy.svg");
}
.skills__item--report {
  background-image: url("/img/report.svg");
}
.skills__item--deadline {
  background-image: url("/img/deadline.svg");
}
.skills__item--contract {
  background-image: url("/img/contract.svg");
}
@media (min-width: 768px) {
  .skills__item {
    margin-bottom: 60px;
  }
}
@media (min-width: 1220px) {
  .skills__item {
    margin-bottom: 40px;
    padding-top: 120px;
    padding-left: 0;
    background-size: 100px 100px;
  }
  .skills__item h3 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 10px;
  }
}

.services {
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
}
@media (min-width: 768px) {
  .services {
    padding-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .services {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .services__wrap {
    display: flex;
  }
}

.services__title {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .services__title {
    font-size: 50px;
  }
}

.services__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .services__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

.services__link {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #FFFFFF;
}

.services__detailed {
  width: fit-content;
  margin: 0;
  margin-top: auto;
  padding-right: 34px;
  text-transform: uppercase;
  background-image: url("/img/arrow.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.module {
  padding: 30px 20px;
  margin-left: 7px;
  background-color: #302F45;
  box-shadow: -7px 7px 0px #5DA6E1;
  border-radius: 10px;
  transition: background-color 0.2s linear;
}
.module:nth-last-child(n+2) {
  margin-bottom: 60px;
}
@media (hover: hover) {
  .module:hover {
    background-color: #4953AA;
  }
}
.module:active {
  background-color: #4953AA;
}
@media (min-width: 768px) {
  .module:nth-last-child(n+2) {
    margin-bottom: 0;
  }
}
@media (min-width: 1220px) {
  .module {
    padding: 40px 20px;
  }
}

.module__item h3 {
  margin: 0;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-size: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid #5DA6E1;
}
@media (min-width: 1220px) {
  .module__item h3 {
    margin-bottom: 30px;
  }
}

.module__text {
  margin: 0;
  margin-bottom: 30px;
}

.profit {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .profit {
    display: flex;
  }
}
@media (min-width: 1220px) {
  .profit {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

.profit__title {
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 30px;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .profit__title {
    font-size: 50px;
  }
}
@media (min-width: 768px) {
  .profit__title {
    margin-bottom: 60px;
  }
}
@media (min-width: 1220px) {
  .profit__title {
    margin-bottom: 80px;
  }
}

.profit__list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 320px;
  background-image: url("/img/profit-img.png");
  background-repeat: no-repeat;
  background-size: 324px auto;
  background-position: top center;
}
@media (min-width: 768px) {
  .profit__list {
    padding-top: 580px;
    background-size: 603px auto;
  }
}
@media (min-width: 1220px) {
  .profit__list {
    padding-top: 0;
    padding-left: 525px;
    background-size: 465px auto;
    background-position: center left;
  }
}

.profit__item {
  padding-left: 86px;
  background-repeat: no-repeat;
}
.profit__item:nth-last-child(n+2) {
  margin-bottom: 50px;
}
.profit__item--traffic {
  background-image: url("/img/traffic.svg");
}
.profit__item--auditorium {
  background-image: url("/img/auditorium.svg");
}
.profit__item--result {
  background-image: url("/img/result.svg");
}
.profit__item h3 {
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 24px;
  border-bottom: 2px solid #4D55B2;
}
@media (min-width: 1220px) {
  .profit__item {
    padding-left: 150px;
    background-size: 100px 100px;
  }
  .profit__item:nth-last-child(n+2) {
    margin-bottom: 60px;
  }
  .profit__item h3 {
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.modal-window {
  display: none;
}

.modal-window__overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 150;
}

.modal-window__window {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #1F1E30;
  opacity: 0;
  transition: opacity 0.2s linear;
  overflow-y: scroll;
  z-index: 200;
}
@media (min-width: 768px) {
  .modal-window__window {
    height: auto;
  }
}
@media (min-width: 1220px) {
  .modal-window__window {
    overflow-y: auto;
    width: 1100px;
    height: auto;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -7px 7px 0px #5DA6E1;
    border-radius: 30px;
  }
}

.modal-window.open .modal-window__window {
  opacity: 1;
}

@media (min-width: 768px) {
  .start {
    display: flex;
  }
}

@media (min-width: 768px) {
  .start__element {
    display: flex;
    justify-content: flex-start;
  }
}

.start__tme {
  display: none;
}
@media (min-width: 768px) {
  .start__tme {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #4953AA;
    border-radius: 50px;
    box-shadow: inset 0px -2px 0px #FFFFFF;
    transition: background-color 0.35s ease-in-out;
    z-index: 2;
  }
}
@media (min-width: 768px) and (hover: hover) {
  .start__tme:hover {
    background-color: #9177D7;
  }
}
@media (min-width: 768px) {
  .start__tme:active {
    color: #9177D7;
  }
}
@media (min-width: 768px) {
  .start__tme svg {
    width: 27px;
    height: 20px;
  }
}
@media (min-width: 768px) and (min-width: 1220px) {
  .start__tme {
    width: 65px;
    height: 65px;
  }
  .start__tme svg {
    width: 37px;
    height: 30px;
  }
}

.start__content {
  margin-bottom: 60px;
  padding-bottom: 384px;
  background-repeat: no-repeat;
  background-size: 324px auto;
  background-position: center bottom;
}
.start__content--seo {
  background-image: url("/img/seo-start.png");
}
.start__content--serm {
  background-image: url("/img/serm-start.png");
}
.start__content--web {
  background-image: url("/img/web-start.png");
}
.start__content--audit {
  background-image: url("/img/audit-start.png");
}
.start__content--about {
  background-image: url("/img/about-start.png");
}
@media (min-width: 768px) {
  .start__content {
    margin-bottom: 100px;
    padding-bottom: 660px;
    padding-top: 15px;
    background-size: 603px auto;
  }
}
@media (min-width: 1220px) {
  .start__content {
    margin-bottom: 200px;
    padding-top: 20px;
    padding-bottom: 0;
    padding-right: 530px;
    background-size: 505px auto;
    background-position: right center;
  }
  .start__content--serm {
    padding-right: 450px;
  }
}

.start__subtitle {
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  color: #3B70AE;
}
@media (min-width: 768px) {
  .start__subtitle {
    font-size: 23px;
    margin-bottom: 67px;
  }
}

.start__title {
  margin: 0;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .start__title {
    font-size: 64px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1220px) {
  .start__title {
    font-size: 65px;
  }
}

.start__text {
  margin: 0;
  margin-bottom: 40px;
  font-size: 18px;
}
@media (min-width: 1220px) {
  .start__text {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) {
  .start__button {
    width: 50%;
    padding: 20px;
  }
}
@media (min-width: 1220px) {
  .start__button {
    width: fit-content;
    padding: 25px 35px;
  }
}

@media (min-width: 768px) {
  .tasks {
    display: flex;
  }
}

.tasks__content {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .tasks__content {
    margin-bottom: 60px;
  }
}
@media (min-width: 1220px) {
  .tasks__content {
    margin-bottom: 120px;
  }
}

.tasks__title {
  margin: 0;
  font-size: 40px;
  text-transform: uppercase;
  color: #96CDFF;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .tasks__title {
    font-size: 50px;
    margin-bottom: 60px;
  }
}
@media (min-width: 1220px) {
  .tasks__title {
    margin-bottom: 50px;
  }
}

.tasks__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 1220px) {
  .tasks__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

.tasks__item {
  margin-bottom: 10px;
  padding: 20px 0 20px 86px;
  background-repeat: no-repeat;
  background-position: left center;
}
.tasks__item--time {
  background-image: url("/img/time.svg");
}
.tasks__item--reviews {
  background-image: url("/img/reviews.svg");
}
.tasks__item--base {
  background-image: url("/img/base.svg");
}
@media (min-width: 768px) {
  .tasks__item {
    margin-bottom: 40px;
  }
}
@media (min-width: 1220px) {
  .tasks__item {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
    padding-left: 120px;
    background-size: 100px 100px;
  }
}

.tasks__text {
  margin: 0;
  font-size: 24px;
}

.ranking {
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
  padding-top: 60px;
  padding-bottom: 67px;
}
@media (min-width: 768px) {
  .ranking {
    padding-bottom: 87px;
  }
}
@media (min-width: 1220px) {
  .ranking {
    padding-top: 80px;
    padding-bottom: 107px;
  }
}

@media (min-width: 768px) {
  .ranking__wrap {
    display: flex;
  }
}

.ranking__title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 40px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .ranking__title {
    font-size: 50px;
  }
}

.ranking__text {
  margin: 0;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .ranking__text {
    margin-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .ranking__text {
    width: 65%;
    font-size: 24px;
    margin-bottom: 60px;
  }
}

.ranking__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .ranking__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
@media (min-width: 1220px) {
  .ranking__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }
}

.effect {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .effect {
    display: flex;
  }
}
@media (min-width: 1220px) {
  .effect {
    padding-top: 80px;
  }
}

.effect__content {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .effect__content {
    margin-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .effect__content {
    margin-bottom: 100px;
  }
}

.seo-property {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .seo-property {
    margin-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .seo-property {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
  }
}

.seo-property__title {
  margin: 0;
  margin-bottom: 40px;
  font-size: 40px;
  text-transform: uppercase;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .seo-property__title {
    margin-bottom: 80px;
    font-size: 50px;
  }
}
@media (min-width: 1220px) {
  .seo-property__title {
    width: 40%;
    margin-right: 60px;
    margin-bottom: 0;
  }
}

.seo-property__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 1220px) {
  .seo-property__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

.seo-property__item {
  margin-bottom: 20px;
  padding: 20px 0 20px 86px;
  background-repeat: no-repeat;
  background-position: left center;
}
.seo-property__item--query {
  background-image: url("/img/query.svg");
}
.seo-property__item--target {
  background-image: url("/img/target.svg");
}
.seo-property__item--view {
  background-image: url("/img/view.svg");
}
.seo-property__item--refused {
  background-image: url("/img/refused.svg");
}
@media (min-width: 768px) {
  .seo-property__item {
    margin-bottom: 30px;
  }
}
@media (min-width: 1220px) {
  .seo-property__item {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 120px;
    background-size: 100px auto;
  }
}

.seo-step {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .seo-step {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 60px;
  }
}
@media (min-width: 1220px) {
  .seo-step {
    grid-template-columns: repeat(2, 1fr);
    gap: 70px;
  }
}

.checklist__text {
  margin: 0;
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-size: 20px;
  border-bottom: 2px solid #5DA6E1;
}
@media (min-width: 768px) {
  .checklist__text {
    font-size: 24px;
  }
}
@media (min-width: 1220px) {
  .checklist__text--outside {
    padding-bottom: 70px;
  }
}

.checklist__list {
  margin-top: 40px;
  padding-left: 15px;
  list-style: disc;
}
@media (min-width: 768px) {
  .checklist__list {
    padding-left: 25px;
  }
}
@media (min-width: 1220px) {
  .checklist__list {
    padding-left: 30px;
  }
}

.checklist__item {
  margin-bottom: 30px;
}

.serm-step {
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .serm-step {
    padding-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .serm-step {
    padding-top: 80px;
  }
}

@media (min-width: 768px) {
  .serm-step__wrap {
    display: flex;
  }
}

.serm-step__title {
  margin: 0;
  margin-bottom: 60px;
  font-size: 40px;
  text-transform: uppercase;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .serm-step__title {
    margin-bottom: 80px;
    font-size: 50px;
  }
}
@media (min-width: 1220px) {
  .serm-step__title {
    margin-bottom: 60px;
  }
}

.serm-step__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 1220px) {
  .serm-step__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
  }
}

.serm-step__item {
  display: flex;
  align-items: center;
}
.serm-step__item:nth-last-child(n+2) {
  margin-bottom: 60px;
}
.serm-step__item h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  color: #96CDFF;
}
@media (min-width: 1220px) {
  .serm-step__item {
    flex-direction: column;
    align-items: flex-start;
  }
  .serm-step__item:nth-last-child(n+2) {
    margin-bottom: 0;
  }
  .serm-step__item--img {
    grid-column: span 2;
    margin: auto 0;
  }
}

.serm-step__img {
  width: 100%;
  height: 135px;
  background-image: url("/img/serm-step__img.png");
  background-size: auto 135px;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 768px) {
  .serm-step__img {
    height: 253px;
    background-size: auto 253px;
  }
}
@media (min-width: 1220px) {
  .serm-step__img {
    height: 311px;
    background-size: auto 311px;
  }
}

.serm-step__number {
  margin: 0;
  margin-right: 20px;
  font-weight: 700;
  font-size: 80px;
  line-height: 104px;
  color: #4D55B2;
}
@media (min-width: 1220px) {
  .serm-step__number {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.serm-step__checklist {
  padding-left: 18px;
  list-style: disc;
}

.web-step {
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .web-step {
    padding-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .web-step {
    padding-top: 80px;
  }
}

@media (min-width: 768px) {
  .web-step__wrap {
    display: flex;
  }
}

.web-step__title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 40px;
  text-transform: uppercase;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .web-step__title {
    margin-bottom: 0px;
    font-size: 50px;
  }
}
@media (min-width: 1220px) {
  .web-step__title {
    margin-bottom: 60px;
  }
}

.web-step__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 1220px) {
  .web-step__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
  }
}

.web-step__item {
  display: flex;
  align-items: center;
}
.web-step__item:nth-last-child(n+2) {
  margin-bottom: 60px;
}
.web-step__item h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  color: #96CDFF;
}
@media (min-width: 1220px) {
  .web-step__item {
    flex-direction: column;
    align-items: flex-start;
  }
  .web-step__item:nth-last-child(n+2) {
    margin-bottom: 0;
  }
  .web-step__item--img {
    margin: auto 0;
  }
}

.web-step__img {
  width: 100%;
  height: 294px;
  background-image: url("/img/web-step_img.png");
  background-size: auto 294px;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 768px) {
  .web-step__img {
    height: 546px;
    background-size: auto 546px;
  }
}
@media (min-width: 1220px) {
  .web-step__img {
    height: 311px;
    background-size: auto 311px;
  }
}

.web-step__number {
  margin: 0;
  margin-right: 20px;
  font-weight: 700;
  font-size: 80px;
  line-height: 104px;
  color: #4D55B2;
}
@media (min-width: 1220px) {
  .web-step__number {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.pluses {
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .pluses {
    display: flex;
    margin-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .pluses {
    margin-bottom: 100px;
  }
}

.pluses__title {
  margin: 0;
  margin-bottom: 60px;
  font-size: 40px;
  text-transform: uppercase;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .pluses__title {
    margin-bottom: 80px;
    font-size: 50px;
  }
}
@media (min-width: 1220px) {
  .pluses__title {
    margin-bottom: 60px;
  }
}

.pluses__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .pluses__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}
@media (min-width: 1220px) {
  .pluses__list {
    gap: 60px;
  }
}

.audit-key {
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
  padding: 60px 0;
}
@media (min-width: 768px) {
  .audit-key {
    padding-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .audit-key {
    padding: 80px 0 100px 0;
  }
}

@media (min-width: 768px) {
  .audit-key__wrap {
    display: flex;
  }
}

.audit-key__title {
  margin: 0;
  margin-bottom: 60px;
  font-size: 40px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .audit-key__title {
    margin-bottom: 80px;
    font-size: 50px;
  }
}
@media (min-width: 1220px) {
  .audit-key__title {
    margin-bottom: 60px;
  }
}

.audit-key__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 1220px) {
  .audit-key__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 26px;
  }
}

.audit-key__item {
  padding-left: 76px;
  background-repeat: no-repeat;
  background-position: left center;
}
.audit-key__item:nth-last-child(n+2) {
  margin-bottom: 30px;
}
.audit-key__item--flow {
  background-image: url("/img/flow.svg");
}
.audit-key__item--promote {
  background-image: url("/img/promote.svg");
}
.audit-key__item--indicator {
  background-image: url("/img/indicator.svg");
}
.audit-key__item--sales {
  background-image: url("/img/sales.svg");
}
@media (min-width: 768px) {
  .audit-key__item {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .audit-key__item:nth-last-child(n+2) {
    margin-bottom: 20px;
  }
}
@media (min-width: 1220px) {
  .audit-key__item {
    padding-left: 0;
    padding-top: 110px;
    background-size: 100px 100px;
    background-position: left top;
  }
  .audit-key__item:nth-last-child(n+2) {
    margin-bottom: 0;
  }
}

.audit-step {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .audit-step {
    display: flex;
    padding-bottom: 20px;
  }
}
@media (min-width: 1220px) {
  .audit-step {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

.audit-step__title {
  margin: 0;
  margin-bottom: 60px;
  font-size: 40px;
  text-transform: uppercase;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .audit-step__title {
    font-size: 50px;
  }
}

.audit-step__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 1220px) {
  .audit-step__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 55px;
  }
}

.audit-step__item {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
@media (min-width: 1220px) {
  .audit-step__item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.audit-step__number {
  margin: 0;
  padding-right: 20px;
  font-weight: 700;
  font-size: 80px;
  line-height: 104px;
  color: #4D55B2;
}
@media (min-width: 1220px) {
  .audit-step__number {
    padding-right: 0;
  }
}

.audit-step__filling h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 24px;
  color: #96CDFF;
}

.audit-step__text {
  margin: 0;
}

.benefit {
  padding: 60px 0;
  background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
}
@media (min-width: 768px) {
  .benefit {
    padding-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .benefit {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) {
  .benefit__wrap {
    display: flex;
  }
}

.benefit__title {
  margin: 0;
  margin-bottom: 60px;
  font-size: 30px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .benefit__title {
    margin-bottom: 80px;
    font-size: 50px;
  }
}

.benefit__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .benefit__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}
@media (min-width: 1220px) {
  .benefit__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }
}

.chosen {
  padding-top: 60px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .chosen {
    display: flex;
    padding-bottom: 80px;
  }
}
@media (min-width: 1220px) {
  .chosen {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1220px) {
  .chosen__content {
    display: flex;
    align-items: center;
  }
}

.chosen__head {
  margin-bottom: 30px;
}
.chosen__head img {
  width: 324px;
  height: auto;
}
@media (min-width: 768px) {
  .chosen__head {
    margin-bottom: 60px;
  }
}
@media (min-width: 1220px) {
  .chosen__head {
    margin-bottom: 0;
    margin-right: 132px;
  }
}

.chosen__title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 40px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .chosen__title {
    font-size: 50px;
    margin-bottom: 20px;
  }
}

.chosen__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .chosen__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (min-width: 1220px) {
  .chosen__list {
    gap: 50px;
  }
}

.chosen__item {
  margin-bottom: 30px;
  padding: 10px 0 10px 86px;
  background-repeat: no-repeat;
  background-position: left center;
}
.chosen__item--experience {
  background-image: url("/img/experience.svg");
}
.chosen__item--period {
  background-image: url("/img/period.svg");
}
.chosen__item--advice {
  background-image: url("/img/advice.svg");
}
.chosen__item--discount {
  background-image: url("/img/discount.svg");
}
.chosen__item--persona {
  background-image: url("/img/persona.svg");
}
.chosen__item--warranty {
  background-image: url("/img/warranty.svg");
}
@media (min-width: 768px) {
  .chosen__item {
    margin-bottom: 0;
    padding: 0;
    padding-top: 86px;
    background-position: left top;
  }
}
@media (min-width: 1220px) {
  .chosen__item {
    padding-top: 120px;
    background-size: 100px 100px;
  }
}

.address {
  margin: 0;
  margin-bottom: 60px;
  padding: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .address {
    margin-bottom: 100px;
    padding-top: 15px;
  }
}

.address__subtitle {
  margin: 0;
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 20px;
  color: #3B70AE;
}
@media (min-width: 768px) {
  .address__subtitle {
    font-size: 23px;
    margin-bottom: 52px;
  }
}
@media (min-width: 1220px) {
  .address__subtitle {
    margin-bottom: 32px;
  }
}

.address__address {
  margin: 0;
  margin-bottom: 30px;
  font-size: 25px;
}
@media (min-width: 768px) {
  .address__address {
    margin-bottom: 20px;
  }
}

.address__mail {
  text-decoration: none;
  color: rgb(0, 0, 0);
  display: block;
  font-size: 21px;
  color: #7785FF;
  margin-bottom: 60px;
  transition: color 0.2s linear;
}
@media (hover: hover) {
  .address__mail:hover {
    color: #FFFFFF;
  }
}
.address__mail:active {
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .address__mail {
    margin-bottom: 55px;
    font-size: 25px;
  }
}
@media (min-width: 1220px) {
  .address__mail {
    width: 20%;
    margin-bottom: 60px;
  }
}

.address__mup {
  width: 100%;
  height: 186px;
}
.address__mup iframe {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .address__mup {
    height: 347px;
  }
}
@media (min-width: 1220px) {
  .address__mup {
    height: 499px;
  }
}

.page-error {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .page-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
  }
}
@media (min-width: 1220px) {
  .page-error {
    padding-top: 100px;
    align-items: flex-start;
    background-image: url("/img/404-img.png");
    background-repeat: no-repeat;
    background-size: 505px auto;
    background-position: right center;
  }
}

.page-error__title {
  margin: 0;
  margin-bottom: 30px;
  font-size: 150px;
  line-height: 40%;
  text-align: center;
}
.page-error__title span {
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
}
@media (min-width: 768px) {
  .page-error__title {
    margin-bottom: 50px;
    font-size: 250px;
    line-height: 30%;
  }
  .page-error__title span {
    font-size: 50px;
  }
}
@media (min-width: 1220px) {
  .page-error__title {
    text-align: left;
  }
}

.page-error__text {
  margin: 0;
  margin-bottom: 30px;
  text-align: center;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .page-error__text {
    margin-bottom: 10px;
    font-size: 30px;
  }
}

.page-error__link {
  text-decoration: none;
  color: rgb(0, 0, 0);
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .page-error__link {
    margin-top: 60px;
    width: 80%;
  }
}
@media (min-width: 1220px) {
  .page-error__link {
    width: 50%;
  }
}

.lawyer {
  padding-top: 30px;
}
@media (min-width: 768px) {
  .lawyer {
    padding-top: 20px;
  }
}
@media (min-width: 1220px) {
  .lawyer {
    padding-top: 50px;
  }
}

.lawyer__title {
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}
@media (min-width: 768px) {
  .lawyer__title {
    font-size: 45px;
  }
}
@media (min-width: 1220px) {
  .lawyer__title {
    margin-bottom: 60px;
    font-size: 70px;
  }
}

.lawyer__date {
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .lawyer__date {
    font-size: 25px;
  }
}
@media (min-width: 1220px) {
  .lawyer__date {
    margin-bottom: 60px;
    font-size: 40px;
  }
}

.lawyer__text {
  margin: 0;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 140%;
}
.lawyer__text span {
  font-weight: 700;
}
@media (min-width: 768px) {
  .lawyer__text {
    font-size: 18px;
  }
}
@media (min-width: 1220px) {
  .lawyer__text {
    margin-bottom: 60px;
    font-size: 25px;
  }
}

.lawyer__subtitle {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  color: #96CDFF;
}
@media (min-width: 768px) {
  .lawyer__subtitle {
    font-size: 25px;
  }
}
@media (min-width: 1220px) {
  .lawyer__subtitle {
    margin-bottom: 20px;
    font-size: 40px;
  }
}

.lawyer__requisites {
  margin: 0;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
}
.lawyer__requisites span {
  font-weight: 700;
}
@media (min-width: 768px) {
  .lawyer__requisites {
    font-size: 18px;
  }
}
@media (min-width: 1220px) {
  .lawyer__requisites {
    margin-bottom: 60px;
    font-size: 25px;
  }
}