.audit-key {
    background-image: linear-gradient(180deg, #0A0B13 0%, #273260 100%);
    padding: 60px 0;

    @media (min-width: $tablet) {
        padding-bottom: 80px; 
    }

    @media (min-width: $desktop) {
        padding: 80px 0 100px 0; 
    }
}

.audit-key__wrap {
    @media (min-width: $tablet) {
        display: flex;
    }
}

.audit-key__title {
    margin: 0;
    margin-bottom: 60px;
    font-size: 40px;
    text-transform: uppercase;

    @media (min-width: $tablet) {
        margin-bottom: 80px;
        font-size: 50px; 
    }

    @media (min-width: $desktop) {
        margin-bottom: 60px;
    }
}

.audit-key__list {
    @include list-reset;

    @media (min-width: $desktop) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 26px;
    }
}

.audit-key__item {
    padding-left: 76px;
    background-repeat: no-repeat;
    background-position: left center;

    &:nth-last-child(n+2) {
        margin-bottom: 30px;
    }

    &--flow {
        background-image: url("/img/flow.svg");
    }

    &--promote {
        background-image: url("/img/promote.svg");
    }

    &--indicator {
        background-image: url("/img/indicator.svg");
    }

    &--sales {
        background-image: url("/img/sales.svg");
    }

    @media (min-width: $tablet) {
        padding-top: 20px;
        padding-bottom: 20px;

        &:nth-last-child(n+2) {
            margin-bottom: 20px;
        }
    }

    @media (min-width: $desktop) {
        padding-left: 0;
        padding-top: 110px;
        background-size: 100px 100px;
        background-position: left top;

        &:nth-last-child(n+2) {
            margin-bottom: 0;
        }
    }
}