html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 320px;
  font-family: $font-family;
  font-size: 18px;
  font-weight: 400;
  background-color: $body-color;
  color: $fonts-color;
  overflow-x: hidden;
  max-width: 100%;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.wrapper {
  @include wrapper-mobile;

  @media (min-width: $tablet) {
    @include wrapper-tablet;
  }

  @media (min-width: $desktop) {
    @include wrapper-desktop;
  }
}

.btn {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 34px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  border: none;
  background-image: linear-gradient(180deg, #96C6FF 0%, #5749AA 100%);
  box-shadow: 2px 2px 0px #3E82B9;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    @media (hover: hover) {
      background-image: linear-gradient(180deg, #CB69FA 0%, #615BBC 100%);
    }
  }

  &:active,
  &:disabled {
    background-image: linear-gradient(180deg, #CB69FA 0%, #615BBC 100%);
  }

  &:disabled {
    cursor: auto;
  }
}

.element {
  @media (min-width: $tablet) {
      display: flex;
      position: relative;
      flex-shrink: 0;
      margin-right: 20px;
      width: 65px;

      &::before,
      &::after {
          content: "";
          position: absolute;
          background-color: #ffffff;
      }
      
      &::before {
          top: -60px;
          right: 0;
          left: -20%;
          margin: auto;
          width: 3px;
          height: 720px;
      }

      &::after {
          top: 660px;
          left: calc(43% - 10px);
          width: 16px;
          height: 16px;
          border-radius: 100%;
      }
    }

    &--promo::before {
      top: 2px;
      height: 100%;
    }

    &--promo::after {
      display: none;
    }

    &--tasks::before {
      height: 350px;
    }

    &--tasks::after {
      top: 285px;
    }

    &--audit-key::before {
      height: 450px;
    }

    &--audit-key::after {
      top: 380px;
    }

    &--audit-step::before {
      height: 450px;
    }

    &--audit-step::after {
      top: 380px;
    }

  @media (min-width: $desktop) {
    margin-right: 40px;

    &::before {
      top: -80px;
      left: 0;
      height: 450px;
    }

    &::after {
      top: 370px;
      left: calc(52% - 10px);
    }

    &--promo::before {
      top: 0;
      height: 100%;
    }

    &--tasks::before {
      height: 450px;
    }

    &--tasks::before {
      height: 240px;
    }

    &--tasks::after {
      top: 160px;
    }

    &--audit-key::before {
      height: 350px;
    }

    &--audit-key::after {
      top: 260px;
    }

    &--audit-step::before {
      height: 350px;
    }

    &--audit-step::after {
      top: 260px;
    }
  }
}