.tasks {
    @media (min-width: $tablet) {
        display: flex;
    }
}

.tasks__content {
    margin-bottom: 30px;

    @media (min-width: $tablet) {
        margin-bottom: 60px; 
    }

    @media (min-width: $desktop) {
        margin-bottom: 120px;
    }
}

.tasks__title {
    margin: 0;
    font-size: 40px;
    text-transform: uppercase;
    color: #96CDFF;
    margin-bottom: 30px;

    @media (min-width: $tablet) {
        font-size: 50px;
        margin-bottom: 60px;  
    }

    @media (min-width: $desktop) {
        margin-bottom: 50px;
    }
}

.tasks__list {
    @include list-reset;

    @media (min-width: $desktop) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
    }
}

.tasks__item {
    margin-bottom: 10px;
    padding: 20px 0 20px 86px;
    background-repeat: no-repeat;
    background-position: left center;

    &--time {
        background-image: url("/img/time.svg");
    }

    &--reviews {
        background-image: url("/img/reviews.svg");
    }

    &--base {
        background-image: url("/img/base.svg");
    }

    @media (min-width: $tablet) {
        margin-bottom: 40px;
    }

    @media (min-width: $desktop) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 0;
        padding-left: 120px;
        background-size: 100px 100px;
    }
}

.tasks__text {
    margin: 0;
    font-size: 24px;
}