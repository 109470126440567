.modal-window {
    display: none;
}

.modal-window__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.2);
    z-index: 150;
}

.modal-window__window {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100%;

    transform: translate(-50%, -50%);

    width: 100%;

    background-color: #1F1E30;
    opacity: 0;
    transition: opacity 0.2s linear;
    overflow-y: scroll;
    z-index: 200;

    @media (min-width: $tablet) {
        height: auto;
    }

    @media (min-width: $desktop) {
        overflow-y: auto;
        width: 1100px;
        height: auto;
        padding-bottom: 50px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -7px 7px 0px #5DA6E1;
        border-radius: 30px;
    }
}

.modal-window.open .modal-window__window {
    opacity: 1;
}

